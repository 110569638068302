<script lang="ts" setup>
</script>

<template>
  <div class="shadow-sm h-14 sticky top-0 z-10 flex items-center border-b border-gray-100 dark:border-gray-800 justify-between px-4 bg-white dark:bg-gray-900">
    <HeaderStart />
    <div class="flex items-center justify-center space-x-4" />
    <HeaderEnd />
  </div>
</template>

<style>

</style>
