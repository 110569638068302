<script lang="ts" setup>
const props = defineProps<{
  small?: boolean
}>()

const colorMode = useColorMode()

const getLogo = computed(() => {
  if (props.small)
    return colorMode.value === 'dark' ? 'img/logo_small_dark.svg' : 'img/logo_small.svg'

  return colorMode.value === 'dark' ? 'img/logo_dark.svg' : 'img/logo.svg'
})
</script>

<template>
  <NuxtImg loading="lazy" :src="getLogo" alt="VPX Host" />
</template>
