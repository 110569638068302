<script lang="ts" setup>
import type { DropdownItem } from '@nuxt/ui/dist/runtime/types'

const colorMode = useColorMode()

const user = useSupabaseUser()

const { availableLocales, locale } = useI18n()

const isDark = computed({
  get() {
    return colorMode.value === 'dark'
  },
  set() {
    colorMode.preference = colorMode.value === 'dark' ? 'light' : 'dark'
  },
})

const items: DropdownItem[][] = [
  [{
    label: user.value?.email || 'Usuário',
  }],
  [{
    label: 'Sair',
    icon: 'i-heroicons-arrow-left-on-rectangle-20-solid',
    shortcuts: ['s'],
    click: async () => {
      await useSupabaseClient().auth.signOut()
      useRouter().push('/login')
    },
  }],
]
</script>

<template>
  <div class="flex items-center space-x-4">
    <ClientOnly>
      <USelectMenu v-model="locale" :options="availableLocales" />
      <UButton
        :icon="isDark ? 'i-heroicons-moon-20-solid' : 'i-heroicons-sun-20-solid'"
        color="gray"
        variant="ghost"
        aria-label="Theme"
        @click="isDark = !isDark"
      />

      <template #fallback>
        <div class="w-8 h-8" />
      </template>

      <UDropdown v-if="user" :items="items" :popper="{ placement: 'bottom-start' }">
        <UButton
          icon="i-heroicons-user-circle-20-solid"
          color="gray"
          variant="ghost"
          aria-label="Profile"
        />
      </UDropdown>
    </ClientOnly>
  </div>
</template>

<style>

</style>
